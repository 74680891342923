// Generated by Framer (9e4a47a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-SbZB6"

const variantClassNames = {zLUvVz2kQ: "framer-v-1f6pogy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, works7, ...props}) => { return {...props, ymeXR_hS2: works7 ?? props.ymeXR_hS2} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;works7?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ymeXR_hS2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "zLUvVz2kQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), sizes: componentViewport?.width || "100vw", ...toResponsiveImage(ymeXR_hS2)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1f6pogy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zLUvVz2kQ"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SbZB6.framer-1n0nsee, .framer-SbZB6 .framer-1n0nsee { display: block; }", ".framer-SbZB6.framer-1f6pogy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 673px; justify-content: center; padding: 0px; position: relative; width: 1160px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SbZB6.framer-1f6pogy { gap: 0px; } .framer-SbZB6.framer-1f6pogy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-SbZB6.framer-1f6pogy > :first-child { margin-left: 0px; } .framer-SbZB6.framer-1f6pogy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 673
 * @framerIntrinsicWidth 1160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ymeXR_hS2":"works7"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruFmiWvoGF: React.ComponentType<Props> = withCSS(Component, css, "framer-SbZB6") as typeof Component;
export default FrameruFmiWvoGF;

FrameruFmiWvoGF.displayName = "Works - 5";

FrameruFmiWvoGF.defaultProps = {height: 673, width: 1160};

addPropertyControls(FrameruFmiWvoGF, {ymeXR_hS2: {title: "Works - 7", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameruFmiWvoGF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})